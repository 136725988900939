import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaddleCheckoutPage from "./PaddleCheckoutPage";
import StripeCheckoutPage from "./StripeCheckoutPage";
import { useHistory } from "react-router-dom";
import PaymentsService from "services/payments.service";
import { parseFeatures } from "../utils";
import { billingConstants } from "../constants";
import BoxLoader from "@common/components/BoxLoader";
import { getFPTid, useQuery } from "@common/utils/common-utils";
import { EventEmitter } from "@common/events/eventEmitter";
import { PAYMENT_FAILURE } from "@common/events/events";
import environment from "@src/environment";
const stripePromise = loadStripe(environment.APP_STRIPE_PUBLISHABLE_KEY);

async function createPayment(orgId, itemId, itemType, metadata) {
    let res;
    if (itemType === billingConstants.ITEM_TYPES.SUBSCRIPTION) {
        res = await PaymentsService.buySubscription(orgId, {
            planId: itemId,
            recurring: true,
            metadata: metadata,
        });
    } else if (itemType === billingConstants.ITEM_TYPES.ADDON) {
        res = await PaymentsService.buyAddOn(orgId, {
            addonId: itemId,
            metadata: metadata,
        });
    } else {
        throw new Error("Invalid item type");
    }

    return res.data;
}

const Checkout = () => {
    const history = useHistory();
    const orgId = useSelector((state) => state.organizationDetails.organization._id);
    const { email } = useSelector((state) => state.authenticationDetails?.user);

    const query = useQuery();
    const utmSource = query.get("utm_source");
    let itemType = query.get("itemType");
    let itemId = query.get("itemId");
    const redirectURL = query.get("redirectUrl");

    const [transactionId, setTransactionId] = useState(query.get("_ptxn"));
    /**
     * Check if the logged-in user has paddle enabled for them.
     * If `OPTION_PADDLE` is disabled or if checkout is opened with `transactionId`,
     * the paddle is enabled by default.
     */
    const [paddleEnabled, setPaddleEnabled] = useState(
        !environment.OPTIONAL_PADDLE || transactionId
            ? true
            : (environment.PADDLE_USERS || []).includes(email),
    );
    const [checkingPaddle, setCheckingPaddle] = useState(true);

    const [isLoading, setLoading] = useState(true);
    const [item, setItem] = useState(null);

    const [mode, setMode] = useState("payment");
    const [confirmationMsg, setConfirmationMsg] = useState("");

    const initiateCheckout = async (checkoutItem) => {
        const {
            chargeCustomer,
            message,
            data: paymentObject,
        } = await createPayment(orgId, checkoutItem._id, itemType, {
            ...(utmSource && { utmSource }),
            fp_tid: getFPTid(),
            email,
        });
        /**
         * This condition will rarely be true. Still handle it.
         */
        if (!chargeCustomer) {
            let url = new URL(
                `/organization/${orgId}/settings/billing/checkout/finish`,
                window.location.origin,
            );

            let params = url.searchParams;
            if (message) {
                params.append("defaultMessage", message);
            }
            if (paymentObject?.id) {
                params.append("stripeInvoiceId", paymentObject.id);
            }
            if (redirectURL) {
                params.append("redirectUrl", encodeURIComponent(redirectURL));
            }

            const path = url.pathname + url.search;

            return history.push(path);
        }

        setTransactionId(paymentObject.id);
    };

    async function fetchItem() {
        if (!itemId || !itemType) return;
        const _getItemFunc =
            itemType === billingConstants.ITEM_TYPES.SUBSCRIPTION
                ? PaymentsService.getPlan(itemId)
                : PaymentsService.getAddon(itemId);

        const itemRes = await _getItemFunc;
        let itemData = itemRes?.data;
        itemData.features = parseFeatures(itemData?.features);

        return itemData;
    }

    useEffect(async () => {
        try {
            if (!transactionId && !(itemId && itemType)) {
                toast.error("Missing checkout details", {
                    autoClose: 2500,
                });
                return;
            }

            /**
             * If transaction has already been created directly open the checkout
             */
            if (transactionId) {
                /**
                 * If `transactionId` is preset, `itemId` & `itemType` won't be.
                 * So fetch these details for further processing.
                 */
                if (!itemId || !itemType) {
                    const response = await PaymentsService.getInvoice(orgId, transactionId, false);
                    ({ itemId, itemType } = response.data);
                }
                const newItem = await fetchItem();
                setItem(newItem);
                setLoading(false);
                setCheckingPaddle(false);
                setPaddleEnabled(true);
                return;
            }

            let _initiateCheckout = paddleEnabled;

            const {
                data: { customer },
            } = await PaymentsService.getActiveSubscription(orgId);

            setCheckingPaddle(false);

            if (customer?.paddleBusinessId) {
                setPaddleEnabled(true);
                _initiateCheckout = true;
            }

            const newItem = await fetchItem();
            setItem(newItem);

            if (itemType === billingConstants.ITEM_TYPES.ADDON) {
                _initiateCheckout && (await initiateCheckout(newItem));
                setLoading(false);
                return;
            }

            /**
             * For plans decide if the subscription can be updated immediately or on cycle end
             */
            const actionTypeRes = await PaymentsService.getChangeActionType(orgId, newItem._id);
            const { actionType } = actionTypeRes.data;

            if (actionType.type === billingConstants.ACTION_TYPES.CHANGE_ON_CYCLE_END) {
                setMode("confirmation");
                setConfirmationMsg(actionType.desc);
                setLoading(false);
            } else if (actionType.type === billingConstants.ACTION_TYPES.CHANGE_NOW) {
                _initiateCheckout && (await initiateCheckout(newItem));
                setLoading(false);
            } else {
                throw new Error(`Invalid action type: ${actionType?.type}`);
            }
        } catch (err) {
            const errMsg =
                err.response?.data?.message || "We're unable to process your request at the moment";
            EventEmitter.dispatch(PAYMENT_FAILURE, {
                err,
                message: errMsg,
            });
            history.push(`/organization/${orgId}/settings/billing`);
            toast.error(errMsg, {
                autoClose: 2500,
            });
        }
    }, []);

    return (
        <BoxLoader stretch={true} opacity={"10"} zIndex={99} loading={isLoading}>
            {!checkingPaddle ? (
                paddleEnabled ? (
                    <PaddleCheckoutPage
                        orgId={orgId}
                        selectedItem={item}
                        itemType={itemType}
                        transactionId={transactionId}
                        mode={mode}
                        confirmationMsg={confirmationMsg}
                    />
                ) : (
                    <Elements stripe={stripePromise}>
                        <StripeCheckoutPage
                            orgId={orgId}
                            selectedItem={item}
                            itemType={itemType}
                            confirmationMsg={confirmationMsg}
                        />
                    </Elements>
                )
            ) : (
                ""
            )}
        </BoxLoader>
    );
};

export default Checkout;
